@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

:root {
  --color-1: #f5f5f5;
  --color-2: #3E414A; 
  --color-3: #B0B0B0;
  --color-8: #FF0066;
  --color-4: #bc9a5a;
  --color-6: #041F3E;
  --color-7: #1A747D;
  --color-5: #FF4F00;

  --font-default: "Josefin Sans", sans-serif;
  --font-header: "Josefin Sans", sans-serif;

}


body {
  background-color: #F5F5F5; 
  margin: 0;
  font-family: var(--font-default);
  color: var(--color-2);
}

h1, h2, h3 {
  font-family: var(--font-header);
  color: var(--color-4);
}



strong, h4 {
  font-weight: 600;
  font-size: 16px;
  color: #0d0d0d;
}
h4 {
  margin-bottom: .5rem;
  margin-top: 1rem;
  font-size: 16px;
  line-height: 1.5;
}

a {
  color: var(--color-6);
  font-weight: 500;
}

p {
  
  color: #0d0d0d;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: 1.8em;
}
strong {
  color: var(--color-4);
  font-weight: 500;
}



li {
  display: list-item;
  text-align: -webkit-match-parent;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}



blockquote {
  border-left: 3px solid var(--color-4);
  margin: 10px;
  padding: 10px;
  font-weight: 500;
  font-style: italic;
}

q {
  quotes: "“" "”" "‘" "’";
}
q::before {
    content: open-quote;
}
q::after {
    content: close-quote;
}


.gold {
  color: var(--color-4);
}

/*************
    2.0 NavBar 
*************/

 .logo {
  width: 100px;
  height: auto;
  /* filter: invert(86%) sepia(21%) saturate(761%) hue-rotate(92deg) brightness(99%) contrast(107%); */
  }

svg path {
    fill: var(--color-4);
}

.navbar {
  background-color: var(--color-2);
  color: var(--color-1);
  z-index: 99;
  position: sticky;
  top: 0;
}

.navbar-collapse {
  background-color: var(--color-2);
  padding: 15px;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}


.nav-link, .nav-item, .nav-link a {
  font-size: 16px;
  color: var(--color-1);
}

.nav-link:hover, .nav-link a:hover {
  color: var(--color-4);
}

.navbar-brand, .navbar-brand:hover {
  color: var(--color-2);
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-size: 2rem;
}



.nav-underline .nav-link.active, .nav-underline .show>.nav-link {
  font-weight: 700;
  color: var(--color-4);
  border-bottom: none;
}

.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
  text-transform: uppercase;
}

.navbar-toggler {
  border-color: var(--color-5);
}

.navbar-toggler-icon {
  background-image: url(
"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(188, 154, 90, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.top-bar {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}

.top-bar .fa {
  margin-left: 4px ;
  padding-right: 4px;
  padding-left: 4px;
}

/*************
  3.0 Forms
*************/
.form-label {
  font-weight: 500;
  color: var(--color-4);
}
.form-control {
  border-radius: 0px;
}

::placeholder {
  opacity: 1; /* Firefox */
  font-weight: 200;
}

::-ms-input-placeholder { /* Edge 12 -18 */
  font-weight: 200;
}


.slick-dots, .slick-dots li button:before {
  color: var(--color-4)!important;
}

.page-title-box {
  background: rgb(63,94,251);
  background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
   height: 160px;
}

h1.page-title {
  margin-top: 20px;
  font-size: 3rem;
  font-weight: 800;
  text-transform: uppercase;
}

.starter {
  margin-top: -46px!important;

  -webkit-box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.75);
}


.site-title {
  font-size: 2rem;
  color:  var(--primary-color)!important;
}

.site-title > span {
  font-size: 1.2rem;
  color: #0d0d0d;
  font-weight: 100;
}

.btn-primary {
  font-size: 1rem;
  padding: 0.5rem 1.25rem;
  text-transform: uppercase;
  border-radius: 0;
  background-color: var(--color-4);
  color: #fff;
  border: 1px solid transparent;
}

.btn:active, .btn:hover {
  background-color: var(--color-6);
}

.testimonial {
  background-color: var(--color-2);
  text-align: center;
}

.testimonial  p {
  color:  var(--color-1);
  font-size: 1.5rem;
  font-weight: 200;
  letter-spacing: 0.1px;

}


footer {
  background-color: var(--color-2);
  color:  var(--color-1);
}

.footer_text {
  font-size: small;
  text-align: center;
  text-decoration: none;
  text-transform: lowercase;
}

footer  p {
  color: var(--color-1);
}

.page_header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.page_header h1{
  background:rgba(0, 0, 0, .6);
  backdrop-filter: blur(5px);
  transition: background-color .3s cubic-bezier(.42, .00, .58, 1.00);
  color: var(--color-4);
  padding: 5px;
}


.dark_blur{
  background:rgba(0, 0, 0, .5);
  backdrop-filter: blur(4px);
  transition: background-color .3s cubic-bezier(.42, .00, .58, 1.00);
  padding: 1rem;
}


.blur{
  background:rgba(252, 245, 235, .2);
  backdrop-filter: blur(4px);
  transition: background-color .3s cubic-bezier(.42, .00, .58, 1.00);
  padding: 4rem;
}

.testimonial-slider {
  height: 100vh;
  margin: auto;
  padding: 52px 25px;
  text-align: center;
}

.testimonial-caption > p {
  font-size: 1.5rem;
  color: white;
  font-style: italic;

}

.testimonial-caption > p >  span {
  color: var(--color-5)!important;
  font-weight: 700;
  font-style: normal;
}

.construction_slider_slide > img {
  width: 100%;
  height: auto;
}


.home-slider-slide {
  height: 450px;
  background-position-y: -200px;
  background-repeat: no-repeat;
}


.home-slider .slick-list {
  max-height: 450px;
  width: 100%;
  padding: 0px 0px!important;
  flex: 1;
}

.header_image {
  width: 100%;
  object-fit: cover;
  height: 300px;
  object-position: 0% 50%;
}

.square_image {
  min-width: 100%;
  max-height: 300px;
  object-fit: cover;
  object-position: 100% 0;
  min-height: 300px;
}

.no_mob {
  display: block;
}

.mob_only {
  display: none;
}

.project_photo {
  height: 200px;
  width: 100%;
  padding: 5px;
  object-fit: cover;
  object-position: 100% 0;
 }

 .intro_text {
  font-size: 3rem;
 }

 /* .start {
  margin-top: 49px;
}
   */

/*<500px S*/
@media only screen and (max-width: 499px) { 

  body {
    line-height: normal;
  }

  .dark_blur {
    padding: 0px;
    width: 100%;
  }

  .project_photo {
    max-height: 120;
   }


   .navbar-brand, .navbar-brand:hover {
    font-size: 16px;
   }

   .navbar {
    background-color: var(--color-1);
    color: var(--color-4);
  }

  .intro_text {
    font-size: 2rem;
   }


  .testimonial-slider {
    height: 100vh;
    margin: auto;
    padding: 20px;
    text-align: center;
  }

  .testimonial-caption > p {
    font-size: block;

  }

  footer {
    text-align: center;
  }

  .page_header, .page_header h1 {
    height: 150px;
  }


  .header_image {
    height: 150px;
  }

  .no_mob {
    display: none;
  }
  
  .mob_only {
    display: inline-grid;
  }



}

/*500-1200 M*/
@media only screen and (min-width: 500px) and (max-width: 1199px) { }

/*>1200px L*/
@media only screen and (min-width: 1200px) {
  .page_header {
    height: 350px;
  }
 }

